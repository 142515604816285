import React from "react"
// import { rhythm } from "../utils/typography"
import Sitename from "./moleculs/siteTitle"
import Nav from "./organisms/navigation"
import {ProvideMediaMatchers, MediaMatcher} from "react-media-match"


class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`

    return (
      <ProvideMediaMatchers>
        <MediaMatcher
          mobile={"mobile"}
          tablet={"tablet"}
          desktop={"render desktop"}
        />
        <head>
          <Sitename location={location} rootPath={rootPath} />
          <Nav className={`navi text-4xl`} />
        </head>
        <main>{children}</main>
        <footer className="mt-24 text-center"> &copy; {title}, {new Date().getFullYear()} </footer>
      </ProvideMediaMatchers>
    )
  }
}

export default Layout
